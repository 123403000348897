import request from '@/util/request'

export function getCaseList(params) {
  return request({
    url: '/Case.asmx/GetCaseList',
    method: 'GET',
    params,
  })
}

export function getCaseInfo(params) {
  return request({
    url: '/Case.asmx/GetCaseInfo',
    method: 'GET',
    params,
  })
}

export function addCase(params) {
  return request({
    url: '/Case.asmx/AddCase',
    method: 'GET',
    params,
  })
}

<template>
  <div style="margin:14px">
    <van-nav-bar title="办案新增" />
    <van-form @submit="onSubmit">
      <van-field
        v-model="formData.shopName"
        name="shopName"
        label="店铺名称"
        :rules="[{ required: true, message: '请填写店铺名称' }]"
      />
      <van-field
        v-model="formData.decisionId"
        name="decisionId"
        label="决策书编号"
        placeholder="请输入决策书编号"
        :rules="[{ required: true, message: '请填写决策书编号' }]"
      />
      <!--      选择中队-->
      <team ref="team" :team-id="formData.teamId" @teamChange="teamChange" />
      <!--      选择所属网格-->
      <team-cell
        ref="teamCell"
        :team-id="formData.teamId"
        :cell-id="formData.cellId"
        @teamCellChange="teamCellChange"
      />
      <!--      选择主办人-->
      <cell-person
        ref="cellPerson"
        :cell-id="formData.cellId"
        :person-id="formData.personId"
        @cellPersonChange="cellPersonChange"
      />
      <!--      选择协同人员-->
      <with-person
        ref="withPerson"
        :cell-id="formData.cellId"
        :user-id="formData.personId"
        @withPersonChange="withPersonChange"
      />
      <van-field
        readonly
        clickable
        :value="formData.decisionDate"
        label="决策书日期"
        placeholder="点击选择时间"
        :rules="[{ required: true, message: '请选择时间' }]"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="date"
          title="决策书日期"
          type="datetime"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field readonly label="上传决策书" />
      <div style="padding: 10px;background: white">
        <upload-file :limit="2" @fetch-data="fetchDecisionShots" />
      </div>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { Dialog } from 'vant'
  import Base64 from '@/util/Base64'
  import moment from 'moment'
  import store from '@/store'
  import uploadFile from '@/components/UploadFile'
  import team from '@/components/Team'
  import teamCell from '@/components/TeamCell'
  import cellPerson from '@/components/CellPerson'
  import withPerson from '@/components/WithPerson'
  import { addCase } from '@/api/outDoor/case/case'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'ShopAdd',
    components: {
      team,
      teamCell,
      cellPerson,
      withPerson,
      uploadFile,
    },
    data() {
      return {
        showPicker: false,
        date: new Date(),
        decisionShotsArr: [], //决策书照片
        withPersonArr: [],

        formData: {
          shopId: 475, //店铺ID
          shopName: '', //店铺名称
          decisionId: null, //决策书编号
          teamId: null,
          cellId: null,
          personId: null,
          withPersonId: '',
          decisionDate: '', //决策书日期
          decisionShots: '', //决策书照片
          userId: null,
        },
      }
    },
    created() {
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      const teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.formData.userId = parseInt(userInfo.User_ID)
      this.formData.personId = parseInt(userInfo.User_ID)
      this.formData.teamId = parseInt(teamInfo.Team_ID)
      this.formData.cellId = parseInt(teamInfo.Cell_ID)
      this.formData.shopId = Base64.decode(this.$route.query.shopID)
      this.formData.shopName = Base64.decode(this.$route.query.shopName)
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'caseAdd',
        '城管-办案新增',
        'zzdcg.yy.gov.cn/caseAdd'
      )
    },
    methods: {
      //提交
      async onSubmit() {
        this.formData.decisionShots = this.decisionShotsArr.join('|')
        this.formData.withPersonId = this.withPersonArr.join(',')
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在提交中...',
          forbidClick: true,
        })
        const { msg, success } = await addCase({
          ShopID: this.formData.shopId,
          DecisionID: this.formData.decisionId,
          Team_ID: this.formData.teamId,
          Cell_ID: this.formData.cellId,
          Person_ID: this.formData.personId,
          DecisionDate: this.formData.decisionDate,
          UserID: this.formData.userId,
          WithPersons: this.formData.withPersonId,
          DecisionShots: this.formData.decisionShots,
        })
        Toast.clear()
        Dialog.alert({
          message: '' + msg,
        }).then(() => {
          if (success) {
            this.toLIst()
          }
        })
      },

      //跳转至列表
      toLIst() {
        this.$router.push({
          path: '/case',
          query: {
            shopID: Base64.encode(this.formData.shopId),
          },
        })
      },

      //日期选择回调
      onConfirm(date) {
        this.formData.decisionDate = moment(date).format('YYYY-MM-DD HH:mm')
        this.showPicker = false
      },

      //选择器回调方法
      teamChange(teamId) {
        this.formData.teamId = teamId
        this.$refs['teamCell'].reset()
        this.$refs['cellPerson'].reset()
        this.formData.cellId = null
        this.formData.personId = null
      },
      teamCellChange(cellId) {
        this.formData.cellId = cellId
        this.$refs['cellPerson'].reset()
        this.formData.personId = null
      },
      cellPersonChange(personId) {
        this.formData.personId = personId
      },
      withPersonChange(val) {
        this.withPersonArr = []
        val.forEach((item) => this.withPersonArr.push(item.value))
      },

      //照片上传回调方法
      fetchDecisionShots(pathList) {
        this.decisionShotsArr = pathList
      },
    },
  }
</script>

<style scoped></style>
